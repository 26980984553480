
























































































































































































import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class Create extends Vue {
  openModal() {
    this.$bvModal.show("edit-service");
  }
}
